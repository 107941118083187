import React, { createContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  signInWithCredential,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseApp';

export interface IAuthUser {
  email: string;
  photoURL: string;
  displayName: string;
  uid: string;
  accessToken?: string;
}

export interface AuthContextType {
  user: IAuthUser | null;
  authLoaded: boolean;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  signInWithToken: (token: string) => Promise<void>;
  signInWithEmail: (email: string, password: string) => Promise<void>;
  signUpWithEmail: (email: string, password: string) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  authLoaded: false,
  signInWithGoogle: async () => {},
  signOut: async () => {},
  signInWithToken: async () => {},
  signInWithEmail: async () => {},
  signUpWithEmail: async () => {},
});

export const AuthProvider: React.FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState<IAuthUser | null>(null);
  const [authLoaded, setLoaded] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((u) => {
      setUser(u as any as IAuthUser);
      setLoaded(true);
    });

    return unsubscribe;
  }, []);

  const signInWithGoogle = React.useCallback(async () => {
    const googleProvider = new GoogleAuthProvider();
    await signInWithPopup(auth, googleProvider);
    navigate('/get-started');
  }, [navigate]);

  const signOut = React.useCallback(async () => {
    await auth.signOut();
    navigate('/login');
  }, [navigate]);

  const signInWithToken = async (googleToken: string) => {
    const credential = GoogleAuthProvider.credential(null, googleToken);
    await signInWithCredential(auth, credential);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const signInWithEmail = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/get-started');
    } catch (error: any) {
      let errorMessage: string;
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'Invalid email address. Please check and try again.';
      } else if (error.code === 'auth/missing-password') {
        errorMessage = 'Password is required. Please enter your password.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Incorrect password. Please try again.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account found with this email.';
      } else {
        errorMessage = error.message || 'Login failed: An unknown error occurred.';
      }
      throw new Error(errorMessage);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const signUpWithEmail = async (email: string, password: string) => {
    await createUserWithEmailAndPassword(auth, email, password);

    navigate('/get-started');
  };

  const contextValue: AuthContextType = React.useMemo(
    () => ({
      user,
      authLoaded,
      signInWithGoogle,
      signOut,
      signInWithToken,
      signInWithEmail,
      signUpWithEmail,
    }),
    [
      user,
      authLoaded,
      signInWithGoogle,
      signOut,
      signInWithEmail,
      signUpWithEmail,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
