import * as React from 'react';

import { makeStyles } from '@mui/styles';
import { ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { WorkspaceContext } from '../../providers/WorkspaceProvider';
import { WorkspaceUserRole } from '../../../api/workspaces';
import logo from '../../../assets/img/stepsy_logo_white.png';
import rocket from '../../../assets/icons/rocket.svg';
import settings from '../../../assets/icons/settings.svg';
import users from '../../../assets/icons/users.svg';
import subscription from '../../../assets/icons/subscription.svg';

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px 16px',
  },

  userBlock: {},

  navItem: {
    padding: '16px !important',
    height: '56px',
    borderRadius: '8px !important',

    '&.active': {
      '&:hover': {
        backgroundColor: '#302E9E',
      },

      backgroundColor: '#302E9E',
    },
  },
  logo: {
    marginBottom: '48px',
    width: '127px',
  },
}));

export const Sidebar: React.FC = () => {
  const classes = useStyles();

  const { role } = React.useContext(WorkspaceContext);

  return (
    <div className={classes.root}>
      <img src={logo} alt="logo" className={classes.logo} />

      <ListItemButton
        className={classes.navItem}
        component={NavLink}
        to="/get-started"
      >
        <ListItemIcon>
          <img src={rocket} alt="rocket" />
        </ListItemIcon>
        <ListItemText primary="Get started" color="white" />
      </ListItemButton>

      <ListItemButton
        className={classes.navItem}
        component={NavLink}
        to="/team"
      >
        <ListItemIcon>
          <img src={users} alt="users" />
        </ListItemIcon>
        <ListItemText primary="Users" color="white" />
      </ListItemButton>

      {role === WorkspaceUserRole.Admin ? (
        <ListItemButton
          className={classes.navItem}
          component={NavLink}
          to="/subscription"
        >
          <ListItemIcon>
            <img src={subscription} alt="subscription" />
          </ListItemIcon>
          <ListItemText primary="Subscription" color="white" />
        </ListItemButton>
      ) : null}
    </div>
  );
};

export default Sidebar;
