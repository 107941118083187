import * as React from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../providers/AuthProvider';
import Layout from '../components/Layout';
import whatcanimg from '../../assets/img/whatcando.webp';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  title: {
    display: 'block',
    textAlign: 'center',
    marginBottom: '20px !important',
  },
}));

const LoginScene: React.FC = () => {
  const classes = useStyles();
  const { signInWithGoogle, user, signInWithEmail } = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  const signIn = React.useCallback(() => {
    signInWithGoogle();
  }, [signInWithGoogle]);

  React.useEffect(() => {
    if (user) {
      navigate('/get-started');
    }
  }, [navigate, user]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLocalLogin = async () => {
    try {
      await signInWithEmail(email, password);
    } catch (err: any) {
      setError(err.message);
    }
  };

  return (
    <Layout>
      <Box className={classes.root}>
        <Box
          sx={{
            width: '600px',
            borderRight: '1px solid #E5EAF2',
            padding: '48px',
          }}
        >
          <h1>Document your steps in no time</h1>

          <p>
            Simple yet powerful software to create software operating
            procedures, process tasks, step-by-step guides and manuals and share
            with your coleagues and customers
          </p>

          <Box sx={{ width: '100%' }}>
            <img src={whatcanimg} alt="" style={{ width: '100%' }} />
          </Box>
        </Box>

        <Box sx={{ width: '600px', padding: '48px', textAlign: 'center' }}>
          <Typography variant="h5" className={classes.title} fontWeight="bold">
            Sign in
          </Typography>

          <Box mb="20px">
            <Typography
              fontWeight={600}
              textAlign="left"
              color="black"
              fontSize="16px"
              mb={1}
            >
              Email
            </Typography>
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError('');
              }}
              placeholder="Enter your email"
              fullWidth
            />
          </Box>
          <Box mb="20px">
            <Typography
              fontWeight={600}
              textAlign="left"
              color="black"
              fontSize="16px"
              mb={1}
            >
              Password
            </Typography>
            <TextField
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setError('');
              }}
              placeholder="Enter your password"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {error && <Typography color="error">{error}</Typography>}
          <Button
            onClick={handleLocalLogin}
            variant="contained"
            color="primary"
            fullWidth
            sx={{ height: 44 }}
            disabled={!email || !password}
          >
            Log In
          </Button>
          <Typography style={{ margin: '20px 0' }}>or</Typography>
          <Button
            startIcon={<GoogleIcon />}
            variant="contained"
            onClick={signIn}
            size="large"
            style={{ width: '100%' }}
          >
            Sign in with Google
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default LoginScene;
